<script context="module">
  let albedo

  export function connect() {
    return albedo
    .publicKey()
    .then((res) => wallet.set(res))
  }
</script>

<script>
  import { onMount } from "svelte";
  import { abrv } from '../helpers/utils'
  import { wallet } from '../stores/wallet'

  onMount(async () => {
    albedo = await import('@albedo-link/intent').then((res) => res.default)
  })
</script>

<header class="flex p-2">
  <h1 class="text-xl font-bold"><a href="/">Colorglyph</a></h1>

  <div class="actions flex items-center ml-auto">
    {#if $wallet?.pubkey}
      <a href="/">Create</a>
      <a href="/me">Profile</a>
      <a href="/glyphs">Home</a>
      <span class="bg-gray-200 rounded px-2 py-1 text-xs">{abrv($wallet?.pubkey)}</span>
    {:else}
      <button class="hover:underline" on:click={connect}>Connect</button>
    {/if}
  </div>
</header>

<style>
.actions a,
.actions span,
.actions button {
  margin-left: 10px;
}

.actions a:hover {
  text-decoration: underline;
}
</style>